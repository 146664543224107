<template>
  <div class="search">
    <caseAddEdit v-if="currView=='addEdit'" @close="currView='index'" @submited="submited" :data="formData"
      :addEditFlag="addEditFlag" />
    <Card v-show="currView=='index'">
      <Row @keydown.enter.native="handleSearch">
        <Collapse v-model="isShow" accordion style="width:100%">
          <Panel name="1">查询条件
            <p slot="content">
              <Form ref="searchForm" :model="searchForm" :label-width="100" label-position="right">
                <Row :gutter="32">
                  <Col span="8">
                  <Form-item label="律师手机" prop="telno">
                    <Input type="text" v-model="searchForm.telno" :maxlength=11 placeholder="请输入律师手机" clearable />
                  </Form-item>
                  </Col>
                  <Col span="8">
                  <Form-item label="律师姓名" prop="nickname">
                    <Input type="text" v-model="searchForm.nickname" :maxlength=10 placeholder="请输入律师姓名" clearable />
                  </Form-item>
                  </Col>
                </Row>
              </Form>
            </p>
          </Panel>
        </Collapse>
      </Row>
      <Row @keydown.enter.native="handleSearch">
        <Form>
          <Form-item style="margin-top:10px;" class="br">
            <Button @click="handleSearch" type="primary" icon="ios-search">搜索</Button>
            <Button @click="add" type="warning" icon="md-add">添加</Button>
          </Form-item>
        </Form>
      </Row>
      <Row>
        <Table :loading="loading" border :columns="columns" :data="data" ref="table" sortable="custom"
          @on-sort-change="changeSort" @on-selection-change="changeSelect"></Table>
      </Row>
      <Row type="flex" justify="end" class="page">
        <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage"
          @on-page-size-change="changePageSize" :page-size-opts="[10,20,50]" size="small" show-total show-elevator
          show-sizer></Page>
      </Row>
    </Card>
    <Modal :title="modalTitle" v-model="modalVisible" :mask-closable='false' :width="500">
      <Form ref="form" :model="form" :label-width="100" :rules="formValidate" label-position="left">
        <FormItem label="律师姓名" prop="nickname">
          <Input v-model="form.nickname" clearable style="width:100%" :maxlength=10 placeholder="张三律师" />
        </FormItem>
        <FormItem label="律师手机" prop="telno">
          <Input v-model="form.telno" clearable style="width:100%" :maxlength=11 placeholder="只能输入手机号码" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="modalVisible=false">取消</Button>
        <Button type="primary" :loading="submitLoading" @click="handleSubmit">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    getWorkGroupList,
    addWorkGroup,
    editWorkGroup,
    deleteWorkGroup
  } from "@/api/busi/group-manage/group";
  import {
    validateMobile2,
  } from "@/libs/validate";
  export default {
    name: "group-manage",
    components: {},
    data() {
      return {
        loading: true, // 表单加载状态
        currView: "index",
        isShow: "1",
        modalType: 0, // 添加或编辑标识
        modalVisible: false, // 添加或编辑显示
        modalTitle: "", // 添加或编辑标题
        searchForm: { // 搜索框初始化对象
          pageNumber: 1, // 当前页数
          pageSize: 10, // 页面大小
          sort: "createTime", // 默认排序字段
          order: "desc", // 默认排序方式
        },
        form: { // 添加或编辑表单对象初始化数据
          telno: "",
          nickname: "",
        },
        // 表单验证规则
        formValidate: {
          telno: [{
              required: true,
              message: "不能为空",
              trigger: "blur"
            },
            {
              validator: validateMobile2,
              trigger: "blur"
            }
          ],
          nickname: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
        },
        submitLoading: false, // 添加或编辑提交状态
        selectList: [], // 多选数据
        selectCount: 0, // 多选计数
        columns: [
          // 表头
          {
            type: "index",
            width: 60,
            align: "center"
          },
          {
            title: "律师手机",
            key: "telno",
            minWidth: 120,
            align: "center",
            sortable: false,
            render: (h, params) => {
              return h('div', {
                style: {
                  textAlign: 'left'
                }
              }, [
                h('span', {
                  style: {}
                }, params.row.telno)
              ]);
            }
          },
          {
            title: "律师姓名",
            key: "nickname",
            minWidth: 120,
            align: "center",
            sortable: false,
            render: (h, params) => {
              return h('div', {
                style: {
                  textAlign: 'left'
                }
              }, [
                h('span', {
                  style: {}
                }, params.row.nickname)
              ]);
            }
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            width: 200,
            render: (h, params) => {
              return h("div", [
                h(
                  "Button", {
                    props: {
                      type: "success",
                      size: "small",
                      icon: "ios-create-outline"
                    },
                    style: {
                      marginRight: "5px"
                    },
                    on: {
                      click: () => {
                        this.edit(params.row);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                  "Button", {
                    props: {
                      type: "warning",
                      size: "small",
                      icon: "md-trash"
                    },
                    on: {
                      click: () => {
                        this.remove(params.row);
                      }
                    }
                  },
                  "删除"
                )
              ]);
            }
          }
        ],
        data: [], // 表单数据
        total: 0 // 表单数据总数
      };
    },
    methods: {
      init() {
        this.getDataList();
      },
      changePage(v) {
        this.searchForm.pageNumber = v;
        this.getDataList();
        this.clearSelectAll();
      },
      changePageSize(v) {
        this.searchForm.pageSize = v;
        this.getDataList();
      },
      handleSearch() {
        this.searchForm.pageNumber = 1;
        this.searchForm.pageSize = 10;
        this.getDataList();
      },
      changeSort(e) {
        this.searchForm.sort = e.key;
        this.searchForm.order = e.order;
        if (e.order === "normal") {
          this.searchForm.order = "";
        }
        this.getDataList();
      },
      getDataList() {
        this.loading = true;
        getWorkGroupList(this.searchForm).then(res => {
          this.loading = false;
          if (res.success) {
            this.data = res.result.records;
            this.total = res.result.total;
          }
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            if (this.modalType === 0) {
              // 添加 避免编辑后传入id等数据 记得删除
              delete this.form.id;
              addWorkGroup(this.form).then(res => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.getDataList();
                  this.modalVisible = false;
                }
              });
            } else {
              // 编辑
              editWorkGroup(this.form).then(res => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.getDataList();
                  this.modalVisible = false;
                }
              });
            }
          }
        });
      },
      add() {
        this.modalType = 0;
        this.modalTitle = "添加";
        this.$refs.form.resetFields();
        delete this.form.id;
        this.modalVisible = true;
      },
      edit(v) {
        this.modalType = 1;
        this.modalTitle = "编辑";
        this.$refs.form.resetFields();
        // 转换null为""
        for (let attr in v) {
          if (v[attr] === null) {
            v[attr] = "";
          }
        }
        let str = JSON.stringify(v);
        let data = JSON.parse(str);
        this.form = data;
        this.modalVisible = true;
      },
      remove(v) {
        this.$Modal.confirm({
          title: "确认删除",
          // 记得确认修改此处
          content: "您确认要删除 " + v.telno + " ?",
          loading: true,
          onOk: () => {
            // 删除
            deleteWorkGroup({
              ids: v.id
            }).then(res => {
              this.$Modal.remove();
              if (res.success) {
                this.$Message.success("操作成功");
                this.getDataList();
              }
            });
          }
        });
      },
    },
    mounted() {
      this.init();
    }
  };
</script>
<style lang="less">
  // 建议引入通用样式 具体路径自行修改 可删除下面样式代码
  // @import "../../../styles/table-common.less";
  .search {
    .operation {
      margin-bottom: 2vh;
    }

    .select-count {
      font-weight: 600;
      color: #40a9ff;
    }

    .select-clear {
      margin-left: 10px;
    }

    .page {
      margin-top: 2vh;
    }

    .drop-down {
      margin-left: 5px;
    }
  }
</style>